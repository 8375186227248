<script lang="ts" setup>
import type { Game } from "@/types";

const props = defineProps<{
	games: Game[];
	title: string;
	isGuest: boolean;
	route?: string;
	isDynamic?: boolean;
	isSection?: boolean;
	type?: "slider_horizontal";
}>();
const emit = defineEmits<{ (event: "update"): void }>();
const { t } = useT();

const { handleToggleToFavoriteClick } = useAddToFavorite();

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const handleFavoriteClick = (game: Game) => {
	handleToggleToFavoriteClick(game || {});
	emit("update");
};

const makeTwoRowsTable = <T,>(arr: T[]): T[][] =>
	arr.reduce((result, current, index, array) => {
		if (index % 2 === 0) {
			result.push(array.slice(index, index + 2) as T[]);
		}
		return result;
	}, [] as T[][]);

const arrayOfGames = computed(() => (props.isDynamic && props.isSection ? makeTwoRowsTable(props.games) : props.games));
</script>

<template>
	<template v-if="arrayOfGames?.length">
		<MSliderWrapper
			class="slider-desktop"
			:list="arrayOfGames as []"
			:title="title"
			:navigation="!isSection"
			:isSection="isSection"
			:offset="0"
		>
			<template #default="{ item }">
				<MGameType
					v-for="(game, index) in Array.isArray(item) ? item : [item]"
					:key="index"
					:game="game"
					:type="type"
					@toggle-favorite="handleFavoriteClick"
				/>
			</template>
			<template #viewAll>
				<NuxtLink v-if="route" :to="route">
					<AText type="base" :data-tid="`slider-${getTitle(title)}-all`" :modifiers="['link', 'underline']">
						{{ t("View all") }}
					</AText>
				</NuxtLink>
			</template>
		</MSliderWrapper>
	</template>
</template>

<style lang="scss" scoped>
:deep(.cards-feed__item) {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
</style>
